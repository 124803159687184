import React from 'react';
import './PolarisBoardConfig.scss';
const PolarisBoardConfig = () => {
  return (
    <div className='PolarisBoardConfig'>
      <div className='polaris-board-config'>
        <h2>폴라리스 보드 구성</h2>

        <div className='polaris-board-config-img'>
          <img src='/images/home/main4.png' alt='polaris_board_config' />
        </div>

        <ul className='polaris-board-config-text'>
          <li>다양한 OS 지원</li>
          <li>교사 학생간 인터렉션</li>
        </ul>
      </div>
    </div>
  );
};

export default PolarisBoardConfig;
