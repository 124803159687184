import React from 'react';
import { PageTemplate } from '../components/base/PageTemplate';
import { Header } from '../components/base/Header';
import { Footer } from '../components/base/Footer';
import { SideNavigation } from '../components/home/SideNavigation';
import { PolarisBoardIntroduction } from '../components/home/PolarisBoardIntroduction';
import { PolarisBoardOperationSystems } from '../components/home/PolarisBoardOperationSystems';
import { PolarisBoardXSchool } from '../components/home/PolarisBoardXSchool';
import { PolarisBoardConfig } from '../components/home/PolarisBoardConfig';
import { PolarisBoardMirroring } from '../components/home/PolarisBoardMirroring';
import { PolarisBoardManagement } from '../components/home/PolarisBoardManagement';
import { PolarisBoardDesc } from '../components/home/PolarisBoardDesc';

const HomePage = () => {
  return (
    <PageTemplate header={<Header />} footer={<Footer />}>
      <SideNavigation />
      <PolarisBoardIntroduction />
      <PolarisBoardOperationSystems />
      <PolarisBoardXSchool />
      <PolarisBoardConfig />
      <PolarisBoardMirroring />
      <PolarisBoardManagement />
      <PolarisBoardDesc />
    </PageTemplate>
  );
};

export default HomePage;
