import React from 'react';
import './PolarisBoardXSchool.scss';
const PolarisBoardXSchool = () => {
  return (
    <div className='PolarisBoardXSchool'>
      <div className='polaris-board-and-school'>
        <div className='title-wrap'>
          <h2>폴라리스 보드를 이용한 VR 교육!</h2>
          <p>VRWARE School X POLARIS BOARD</p>
        </div>

        <div className='school-contents'>
          <div className='school-content'>
            <img src='/images/home/main3_1.png' alt='school_content_1' />
            <div className='hover-box'>
              <div className='hover-text'>3D 가상공간 만들기</div>
            </div>
          </div>
          <div className='school-content'>
            <img src='/images/home/main3_2.png' alt='school_content_2' />
            <div className='hover-box'>
              <div className='hover-text'>교과서 기반 다양한 오브젝트</div>
            </div>
          </div>
          <div className='school-content'>
            <img src='/images/home/main3_3.png' alt='school_content_3' />
            <div className='hover-box'>
              <div className='hover-text'>다양한 이벤트를 활용한 인터렉티브 학습 진행</div>
            </div>
          </div>
          <div className='school-content'>
            <img src='/images/home/main3_4.png' alt='school_content_4' />
            <div className='hover-box'>
              <div className='hover-text'>코딩 교육</div>
            </div>
          </div>
          <div className='school-content'>
            <img src='/images/home/main3_5.png' alt='school_content_5' />
            <div className='hover-box'>
              <div className='hover-text'>내가 만든 오브젝트 추가</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolarisBoardXSchool;
