import React from 'react';
import './PolarisBoardMirroring.scss';
const PolarisBoardMirroring = () => {
  return (
    <div className='PolarisBoardMirroring'>
      <div className='polaris-board-mirroring'>
        <h2>화면 미러링 기능</h2>

        <div className='mirroring-list'>
          <div className='mirroring-item'>
            <p>전자칠판 화면을 전체 학생에게 공유</p>
            <img src='/images/home/main5_1.png' alt='main5_1' />
          </div>
          <div className='mirroring-item'>
            <p>특정 학생의 화면 공유</p>
            <img src='/images/home/main5_2.png' alt='main5_2' />
          </div>
          <div className='mirroring-item'>
            <p>선생님 PC와 학생 태블릿 화면 공유</p>
            <img src='/images/home/main5_3.png' alt='main5_3' />
          </div>
        </div>

        <p>AP(무선 공유기) 1대로 50명 이상의 학생에게 화면공유</p>
      </div>
    </div>
  );
};

export default PolarisBoardMirroring;
