import React from 'react';
import './Header.scss';
import { Link } from 'react-router-dom';
const Header = () => {
  return (
    <div className='Header'>
      <div className='header-wrap'>
        <div className='header-logo'>
          <Link to='/'>
            <img src='/images/base/header_logo.png' alt='header_logo' />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
