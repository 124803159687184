import React from 'react';
import './PolarisBoardDesc.scss';
const PolarisBoardDesc = () => {
  return (
    <div className='PolarisBoardDesc'>
      <div className='polaris-board-desc'>
        <div className='desc-list'>
          <div className='desc-item blue'>
            <div className='desc-img'>
              <img src='/images/home/main7_1.png' alt='main7_1' />
            </div>
            <p className='desc-title'>사용자 중심 UX</p>
            <p className='desc-sub-title'>전자 칠판 기기에 최적화된 사용자 환경</p>
          </div>
          <div className='desc-item'>
            <div className='desc-img'>
              <img src='/images/home/main7_2.png' alt='main7_2' />
            </div>
            <p className='desc-title'>전용 앱 & 콘텐츠</p>
            <p className='desc-sub-title'>전자 칠판 기기에 유용한 앱이나 콘텐츠 제공</p>
          </div>
          <div className='desc-item blue'>
            <div className='desc-img'>
              <img src='/images/home/main7_3.png' alt='main7_3' />
            </div>
            <p className='desc-title'>윈도우 연동</p>
            <p className='desc-sub-title'>PC나 태블릿 등 다양한 기기 연동 가능</p>
          </div>
          <div className='desc-item'>
            <div className='desc-img'>
              <img src='/images/home/main7_4.png' alt='main7_4' />
            </div>
            <p className='desc-title'>모바일 디바이스 연동</p>
            <p className='desc-sub-title'>스마트폰이나 태블릿과 함께 전자칠판 연동</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolarisBoardDesc;
