import React from 'react';
import './Footer.scss';

const Footer = () => {
  return (
    <div className='Footer'>
      <div className='footer-wrap'>
        <div className='footer-box'>
          <div className='gp-logo'>
            <img src='/images/base/footer-logo.png' alt='globepoint' />
          </div>
          <div className='footer-center'>
            <div className='footer-center-text'>
              <div className='footer-center-bottom'>
                <div>
                  <span>(주)글로브포인트 사업자등록번호 : 107-87-72034</span>
                  <span>대표 조상용</span>
                </div>
                <p>경기도 고양시 일산동구 태극로 60, 1603호 (장항동, 빛마루)</p>
                <div>
                  <span>031-911-0609</span>
                  <span>gpsales@globepoint.co.kr</span>
                </div>
                <p className='copyrights'>Copyrights ⓒ 2020 Globepoint. All rights reserved.</p>
              </div>
            </div>
          </div>
          <ul className='footer-right'>
            <li className='footer-cs'>
              <p>고객센터</p>
            </li>
            <li className='footer-company-ph'>
              <p>031-911-0609</p>
            </li>
            <li className='footer-company-wh'>
              <p>평일 10:00~18:00 (점심시간 12:30~13:30)</p>
              <p>국/공휴일 휴무</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

// const FooterSNS = () => {
//   return (
//     <ul>
//       <li>
//         <a href='https://www.youtube.com/channel/UCZGwK7Ac87irDmPZfoLPvlg' target='_sub'>
//           <img src={youtube} alt='youtube' />
//         </a>
//       </li>
//       <li>
//         <a href='https://www.facebook.com/GlobePoint.Inc/' target='_sub'>
//           <img src={facebook} alt='facebook' />
//         </a>
//       </li>
//       <li>
//         <a href='https://twitter.com/GlobepointK' target='_sub'>
//           <img src={twitter} alt='twitter' />
//         </a>
//       </li>
//       <li>
//         <a href='http://blog.vrware.co.kr/' target='_sub'>
//           <img src={blog} alt='blog' />
//         </a>
//       </li>
//     </ul>
//   );
// };

// const FooterBottomSNS = () => {
//   return (
//     <div className='footer-bottom-sns'>
//       <ul>
//         <li>
//           <a href='https://www.youtube.com/channel/UCZGwK7Ac87irDmPZfoLPvlg' target='_sub'>
//             <img src={youtube} alt='youtube' />
//           </a>
//         </li>
//         <li>
//           <a href='https://www.facebook.com/GlobePoint.Inc/' target='_sub'>
//             <img src={facebook} alt='facebook' />
//           </a>
//         </li>
//         <li>
//           <a href='https://twitter.com/GlobepointK' target='_sub'>
//             <img src={twitter} alt='twitter' />
//           </a>
//         </li>
//         <li>
//           <a href='http://blog.vrware.co.kr/' target='_sub'>
//             <img src={blog} alt='blog' />
//           </a>
//         </li>
//       </ul>
//     </div>
//   );
// };

export default Footer;
