import React from 'react';
import './PolarisBoardOperationSystems.scss';
const PolarisBoardOperationSystems = () => {
  return (
    <div className='PolarisBoardOperationSystems'>
      <div className='polaris-board-os'>
        <h2>폴라리스보드로 가상현실 교육을 진행해 보세요!</h2>

        <div className='platform-imgs'>
          <div className='platform-img'>
            <img src='/images/home/phone.png' alt='phone' />
          </div>
          <div className='platform-img'>
            <img src='/images/home/tablet.png' alt='tablet' />
          </div>
          <div className='platform-img'>
            <img src='/images/home/pc.png' alt='pc' />
          </div>
          <div className='platform-img'>
            <img src='/images/home/wacom.png' alt='wacom' />
          </div>
          <div></div>
        </div>

        <p>안드로이드, IOS 태블릿, 윈도우에서 가상공간을 만들고 체험이 가능합니다.</p>
      </div>
    </div>
  );
};

export default PolarisBoardOperationSystems;
