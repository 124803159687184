import React from 'react';
import './SideNavigation.scss';

const SideNavigation = () => {
  return (
    <div className='SideNavigation'>
      <a href='https://my.vrware.us' target='_sub'>
        <img src='/images/home/navigation.png' alt='navigation' />
      </a>
    </div>
  );
};

export default SideNavigation;
