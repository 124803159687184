import React from 'react';
import './PolarisBoardManagement.scss';
const PolarisBoardManagement = () => {
  return (
    <div className='PolarisBoardManagement'>
      <div className='polaris-board-management'>
        <h2>효율적인 학생 참여 관리</h2>

        <div className='management-list'>
          <div className='management-item'>
            <div className='management-img'>
              <img src='/images/home/main6_1.png' alt='main6_1' />
            </div>
            <p>다른 앱 실행 금지</p>
          </div>
          <div className='management-item'>
            <div className='management-img'>
              <img src='/images/home/main6_2.png' alt='main6_2' />
            </div>
            <p>학생 태블릿 제어</p>
          </div>
          <div className='management-item'>
            <div className='management-img'>
              <img src='/images/home/main6_3.png' alt='main6_3' />
            </div>
            <p>인터넷 실행 금지</p>
          </div>
          <div className='management-item'>
            <div className='management-img'>
              <img src='/images/home/main6_4.png' alt='main6_4' />
            </div>
            <p>학생 간 파일 공유 허용</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolarisBoardManagement;
