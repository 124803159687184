import React from 'react';
import './PolarisBoardIntroduction.scss';

const PolarisBoardIntroduction = () => {
  return (
    <div className='PolarisBoardIntroduction'>
      <div className='polaris-board-introduction'>
        <div className='introduction-img'>
          <img src='/images/home/intro_logo.png' alt='intro_logo' />
        </div>
        <div className='introduction-txt'>
          <h1>VRWARE POLARIS BOARD란?</h1>
          <p>
            언택트 시대에 맞춰 리모트 워크를 위한 기존 전자칠판의 기능에 업무와 교육 환경 향상을 위한 혁신적인 기능을
            제공하는 완전히 새로운 스마트보드와 가상교육을 위한 VRWARE가 통합된 소프트웨어입니다.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PolarisBoardIntroduction;
